@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap);
:root {
  --color-header-background: #00a6e9;
  --color-header-text: #fff;
  --color-footer-text: #000;

}
@charset "UTF-8";

:root {
  --roboto: "Roboto", sans-serif;
  --Acumin: "ACUMIN PRO CONDENSED", sans-serif;
  --inter: "Inter", sans-serif;
  --plex: "IBM Plex Sans", sans-serif;
  --lato: "Lato", sans-serif;
  --manrope: "Manrope", sans-serif;
  --monstserrat: "MONTSERRAT", sans-serif;

  /*Font sizes*/
  --font_size_h1: 6rem;
  --font_size_h2: 3.75rem;
  --font_size_h3: 3rem;
  --font_size_h4: 2.125rem;
  --font_size_h5: 1.5rem;
  --font_size_h6: 1.25rem;
  --font_size_body: 1rem;
  --font_size_body_large: 1.25rem;
  --font_size_body_small: 0.875rem;
  --font_size_button: 0.875rem;
  --font_size_caption: 0.75rem;
  --font_size_caption_small: 0.625rem;
  --font_size_overline: 0.75rem;
  --font_size_overline_small: 0.625rem;

  /*Font weight*/
  --font_weight_h1: 700;
  --font_weight_h2: 700;
  --font_weight_h3: 700;
  --font_weight_h4: 700;
  --font_weight_h5: 700;
  --font_weight_h6: 700;
  --font_weight_body: 400;
  --font_weight_body_medium: 500;
  --font_weight_button: 700;
  --font_weight_caption: 400;
  --font_weight_caption_small: 500;
  --font_weight_overline: 600;

  /*Letter spacing*/
  --letter_spacing_h1: -0.026em;
  --letter_spacing_h2: -0.025em;
  --letter_spacing_h3: -0.01em;
  --letter_spacing_h4: 0;
  --letter_spacing_h5: 0;
  --letter_spacing_h6: 0.0075em;
  --letter_spacing_body: 0.0275em;
  --letter_spacing_body_bold: 0.022em;
  --letter_spacing_body_large: 0.0075em;
  --letter_spacing_body_large_bold: 0.004em;
  --letter_spacing_body_small: 0.018em;
  --letter_spacing_button: 0.054em;
  --letter_spacing_caption: 0.033em;
  --letter_spacing_caption_small: 0.06em;
  --letter_spacing_overline: 0.0168em;
  --letter_spacing_overline_small: 0.15em;
}

.fonts_roboto__15Jwj {
  font-family: "Roboto", sans-serif;
  font-family: var(--roboto);
}

.fonts_quicksand__H7n6y {
  font-family: var(--quicksand);
}

.fonts_inter__y4SKD {
  font-family: "Inter", sans-serif;
  font-family: var(--inter);
}

.fonts_lato__2rGNc {
  font-family: "Lato", sans-serif;
  font-family: var(--lato);
}

.fonts_plex__KG7g0 {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--plex);
}

.fonts_monrope__2Ly6o {
  font-family: "Manrope", sans-serif;
  font-family: var(--manrope);
}

.fonts_h1__-cuNH,
.fonts_H1__21NaG {
  font-weight: 700;
  font-weight: var(--font_weight_h1);
  font-size: 6rem;
  font-size: var(--font_size_h1);
  letter-spacing: -0.026em;
  letter-spacing: var(--letter_spacing_h1);
}

.fonts_h2__P_MCM,
.fonts_H2__VJ9rO {
  font-weight: 700;
  font-weight: var(--font_weight_h2);
  font-size: 3.75rem;
  font-size: var(--font_size_h2);
  letter-spacing: -0.025em;
  letter-spacing: var(--letter_spacing_h2);
}

.fonts_h3__3s2wr,
.fonts_H3__2CsdR {
  font-weight: 700;
  font-weight: var(--font_weight_h3);
  font-size: 3rem;
  font-size: var(--font_size_h3);
  letter-spacing: -0.01em;
  letter-spacing: var(--letter_spacing_h3);
}

.fonts_h4__2IwbF,
.fonts_H4__2j_im {
  font-weight: 700;
  font-weight: var(--font_weight_h4);
  font-size: 2.125rem;
  font-size: var(--font_size_h4);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h4);
}

.fonts_h5__26iQU,
.fonts_H5__2KJ8G {
  font-weight: 700;
  font-weight: var(--font_weight_h5);
  font-size: 1.5rem;
  font-size: var(--font_size_h5);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h5);
}

.fonts_h6__3MP8m,
.fonts_H6__3LJQs {
  font-weight: 700;
  font-weight: var(--font_weight_h6);
  font-size: 1.25rem;
  font-size: var(--font_size_h6);
  letter-spacing: 0.0075em;
  letter-spacing: var(--letter_spacing_h6);
}

.fonts_body__1dE3t,
.fonts_BodyBig__-HARa {
  font-weight: 400;
  font-weight: var(--font_weight_body);
  font-size: 1rem;
  font-size: var(--font_size_body);
  letter-spacing: 0.0275em;
  letter-spacing: var(--letter_spacing_body);
}

.fonts_button_text__2H5_U {
  font-weight: 700;
  font-weight: var(--font_weight_button);
  font-size: 0.875rem;
  font-size: var(--font_size_button);
  letter-spacing: 0.054em;
  letter-spacing: var(--letter_spacing_button);
}

.fonts_caption__31abE {
  font-weight: 400;
  font-weight: var(--font_weight_caption);
  font-size: 0.75rem;
  font-size: var(--font_size_caption);
  letter-spacing: 0.033em;
  letter-spacing: var(--letter_spacing_caption);
}

.fonts_overline__3QOtf {
  font-weight: 600;
  font-weight: var(--font_weight_overline);
  font-size: 0.75rem;
  font-size: var(--font_size_overline);
  letter-spacing: 0.0168em;
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
}
.style_container___PB6q {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__2xvdr {
  padding-top: 1rem;
}

.style_h4_title__12ls8 {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__3Ft6Z {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__2v3Ao {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__1cDzc,
.style_link__1cDzc:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__1cDzc:hover {
  color: var(--blue70);
}

.style_promo_group__1717i {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__16AUJ {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__rl0c7 {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__3z9pA {
  padding-right: 5rem;
  width: 25%;

}

.style_item_img__2Yowy {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__1717i {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__16AUJ {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.style_container__oDrrd {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__OgGhc {
  padding-top: 1rem;
}

.style_h4_title__2bNZj {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__1iCx8 {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__2cwY- {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__2Er1l,
.style_link__2Er1l:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__2Er1l:hover {
  color: var(--blue70);
}

.style_promo_group__2Pj52 {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__3Pfvo {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__celyk {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__26_FA {
  height: 55px;
}

.style_item_img__1iMgF {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__2Pj52 {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__3Pfvo {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.style_container__3gsJ1 {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__2Mjc4 {
  padding-top: 1rem;
}

.style_h4_title__3kvXH {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__2TDG_ {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__1dpnK {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__2YTrT,
.style_link__2YTrT:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__2YTrT:hover {
  color: var(--blue70);
}

.style_promo_group__1o9EV {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__11TiM {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__6S4bE {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__3EHc3 {
  height: 55px;
}

.style_item_img__4f8Nh {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__1o9EV {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__11TiM {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.style_container__kDlwV {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__2Kkx3 {
  padding-top: 1rem;
}

.style_h4_title__1B-UB {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__32SQP {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__16bL2 {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__3yolg,
.style_link__3yolg:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__3yolg:hover {
  color: var(--blue70);
}

.style_promo_group__1PR7w {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__1CDPY {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__3_hUR {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__1aiUj {
  height: 55px;
}

.style_item_img__32n66 {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__1PR7w {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__1CDPY {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .style_text__16bL2 {
    padding-right: 0;
  }
}
:root{--color-header-background: #00a6e9;--color-header-text: #fff;--color-footer-text: #000}
.container{display:grid;grid-template-areas:"header header " "sidebar content " "footer footer";grid-template-columns:fit-content(100%)}.navbar{grid-area:header;background:var(--color-header-background)}.footer{padding:12px 20px;display:flex;height:10vh;align-items:center;justify-content:space-between;font-weight:700;left:0}.content{top:4.5rem;height:80vh;grid-area:content;margin-right:0;margin-left:0;width:150vh;padding:0 1rem}.left_sidebar{width:180px;grid-area:sidebar;height:80vh;overflow-y:auto;overflow-x:hidden;position:fixed;top:4.5rem;left:0}.sidebar{grid-area:sidebar;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;width:240px;z-index:5;display:block;-webkit-transform:translateX(-100%);transform:translateX(-100%);transition:all 250ms ease-in-out;display:contents;background:var(--grey0);border-radius:0 .5rem .5rem 0}.root{flex-grow:1}.button{float:"right"}.title{flex-grow:1}.main-content{display:inline-block;width:100%}ul{margin:0;padding:0}li{list-style-type:none;list-style-position:outside}.blink{-webkit-animation:blink-animation 3s steps(25, start) infinite;animation:blink-animation 3s steps(25, start) infinite}button:focus{outline:0}
.App_container__11LM7 {
  display: grid;
  grid-template-areas:
    "header header"
    "sidebar content"
    "footer footer";
  grid-template-columns: fit-content(100%);
  padding-top: 1em;
  font-family: var(--Acumin)
}

.App_header__3KLg7 {
  grid-area: header;
  font-family: var(--Acumin)
}

.App_sidebar__23ZAv {
  width: 300px;
  grid-area: sidebar;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 5.5rem;
  left: 0;
  font-family: var(--monstserrat)
}

.App_content__QySdJ {
  margin-top: 60px;
  grid-area: content;
  height: calc(100vh - 160px);
  overflow-y: auto;
  font-family: var(--Acumin)
}

.App_footer__2fwJZ {
  grid-area: footer;
  font-family: var(--Acumin)
}

.App_account__Mdybj {
  color: var(--color-header-text);
  font-family: var(--Acumin)
}

@media only screen and (max-width: 600px) {
  .App_sidebar__23ZAv {
    width: auto;
    height: 100vh;
    margin-top: 30px;
  }
}
/* TREE TYPES*/

._type_def__2meFF li[class*="tree-node"] {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}
._type_dense__32bk7 li[class*="tree-node"] {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}

._type_def__2meFF li[class*="tree-node-expanded"],
._type_dense__32bk7 li[class*="tree-node-expanded"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}

._type_def__2meFF li[class*="selected"],
._type_dense__32bk7 li[class*="selected"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}

._type_def__2meFF li > div[class*="node-content"] {
  min-height: 2.5rem;
  height: unset;
  margin-bottom: 0.25rem;
  box-shadow: none;
  border-radius: 0.25rem;
  transition: all 200ms ease-in-out;
}
._type_dense__32bk7 li > div[class*="node-content"] {
  min-height: 1.75rem;
  height: unset;
  margin-bottom: 0.125rem;
  box-shadow: none;
  border-radius: 0.125rem;
  transition: all 200ms ease-in-out;
}

._type_def__2meFF
  li
  > div[class*="node-content"]
  > span[class*="node-caret"]:not([class*="node-caret-none"]) {
  width: 2rem;
  height: 2rem;
  min-width: unset;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  border-radius: 100%;
  padding: 0;
}

._type_dense__32bk7 li > div[class*="node-content"] > span[class*="node-caret"] {
  /*width: 1.25rem;
  height: 1.25rem;*/
  min-width: unset;
  margin-left: 0.375rem;
  margin-right: 0.5rem;
  padding: 0;
}
._type_def__2meFF li > div[class*="node-content"] > span[class*="node-caret"] > svg,
._type_def__2meFF span[class*="node-caret-none"] ~ span[class*="node-icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
  margin: auto;
}
._type_def__2meFF li > div[class*="node-content"] > span[class*="node-caret"] > svg {
  margin-top: 0.375rem;
}
._type_def__2meFF span[class*="node-caret-none"] {
  min-width: 0.625rem;
  margin-left: 0.625rem;
}
._type_dense__32bk7 span[class*="node-caret-none"] {
  margin-left: 0 !important;
}

._type_def__2meFF div[with-secondary-label] {
  height: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
._type_def__2meFF div[with-secondary-label] > div {
  /* font-size: var(--font-size-Body2);
  letter-spacing: var(--letter-spacing-Body2);*/
  margin-top: 0.125rem;
}

._type_def__2meFF li[class*="disabled"] > div > span > div[with-secondary-label] > div {
  /* font-size: var(--font-size-Body2);
  letter-spacing: var(--letter-spacing-Body2);*/
}

._type_def__2meFF
  li[class*="disabled"]
  > div
  > span
  > div[with-secondary-label]
  > div
  > span {
  display: inline-block;
}

/*Secondary icon*/
._type_def__2meFF span[class*="node-secondary-label"] > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
/*----*/

/*TREE*/

._view_flat__31NLW {
  color: var(--color100);
}
._view_flat__31NLW span > svg {
  color: var(--color50);
}
._view_flat__31NLW li > div:first-of-type:hover {
  box-shadow: var(--shadow2dp);
  transition: all 150ms ease-in;
}
._view_flat__31NLW li > div:hover {
  background-color: unset;
}
._view_flat__31NLW li[class*="selected"] > div:first-of-type {
  background-color: var(--color5);
  color: var(--color100);
  box-shadow: none;
  transition: all 150ms ease-in;
}
._view_flat__31NLW li[class*="selected"] svg {
  color: var(--color70);
  transition: all 150ms ease-in;
}

._view_flat__31NLW li > div:first-of-type:hover > span:first-of-type {
  background: var(--color10);
  transition: all 150ms ease-in;
}
._view_flat__31NLW[class*="dense"] li > div:first-of-type:hover > span:first-of-type {
  background: none;
  transition: all 150ms ease-in;
}
._view_flat__31NLW
  li:not([class*="disabled"])
  > div:first-of-type:hover
  > span[class*="node-caret"]:first-of-type
  > svg {
  color: var(--color100);
  transition: all 150ms ease-in;
}
._view_flat__31NLW li:hover > div:first-of-type > span[class*="none"] {
  background: none;
  transition: all 150ms ease-in;
}
._view_flat__31NLW li:hover {
  cursor: pointer;
}
._view_flat__31NLW span[class*="node-caret-closed"] > svg {
  color: var(--color40);
  transition: all 150ms ease-in;
}
._view_flat__31NLW span[class*="node-caret-open"] > svg {
  color: var(--color70);
  transition: all 150ms ease-in;
}
._view_flat__31NLW div[with-secondary-label] > div {
  color: var(--color60);
}

/*DISABLED*/
._view_flat__31NLW li[class*="disabled"],
._view_flat__31NLW li[class*="disabled"] > div:first-of-type,
._view_flat__31NLW li[class*="disabled"] > div:first-of-type:hover > span,
._view_flat__31NLW li[class*="disabled"] > div:first-of-type:hover > span > svg,
._view_flat__31NLW li[class*="disabled"] > div:first-of-type > span > svg {
  color: var(--color20);
  box-shadow: none;
  background: unset;
}
/*Custom*/
._view_flat__31NLW li[class*="disabled"] > div > span > div[with-secondary-label] > div {
  color: var(--yellow70);
}
._view_flat__31NLW
  li[class*="disabled"]
  > div
  > span
  > div[with-secondary-label]
  > div
  > span
  > svg {
  color: var(--yellow70);
}

/* COLORS*/
:root {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/

  /*TABLE*/
  --flat-def-bg-table-hover: var(--color5);
  --flat-def-color-table: var(--color50);
  --flat-def-border-table: var(--color10);
  --flat-def-border2-table: var(--color50);
  --flat-def-borderlast-table: var(--color40);
  --flat-def-color-table-pag: var(--color60);
  --flat-def-color-checkbox: var(--color70);

  --filled-def-bg-table: var(--color40);
  --filled-def-bg-table-hover: var(--color50);
  --filled-def-bg-head-table: var(--color70);
  --filled-def-border-table: var(--color30);
  --filled-def-border2-table: var(--color10);
  --filled-def-head-table-sort: var(--color60);
  --filled-def-color-checkbox: var(--color20);

  --smooth-def-bg-head-table: var(--color10);
  --smooth-def-bg-table: var(--color0);
  --smooth-def-border-table: var(--color10);
  --smooth-def-border2-table: var(--color50);
  --smooth-def-color-table: var(--color50);
  --smooth-def-color-table-pag: var(--color60);

  --raised-def-border-table: var(--color10);
  --raised-def-border2-table: var(--color50);
  --raised-def-prim-color-table: var(--color50);
  /*-------------------*/
}

.color_select__3DOJT {
  /*TABLE*/
  --flat-bg-table-hover: var(--color5);
  --flat-color-table: var(--color50);
  --flat-border-table: var(--color10);
  --flat-border2-table: var(--color50);
  --flat-borderlast-table: var(--color40);
  --flat-color-table-pag: var(--color60);
  --flat-color-checkbox: var(--color70);

  --filled-bg-table: var(--color40);
  --filled-bg-table-hover: var(--color50);
  --filled-bg-head-table: var(--color70);
  --filled-border-table: var(--color30);
  --filled-border2-table: var(--color10);
  --filled-head-table-sort: var(--color60);
  --filled-color-checkbox: var(--color20);

  --smooth-bg-head-table: var(--color10);
  --smooth-bg-table: var(--color0);
  --smooth-border-table: var(--color10);
  --smooth-border2-table: var(--color50);
  --smooth-color-table: var(--color50);
  --smooth-color-table-pag: var(--color60);

  --raised-border-table: var(--color10);
  --raised-border2-table: var(--color50);
  --raised-prim-color-table: var(--color50);
  /*-------------------*/
}

.color_primary__2aWGC {
  --color0: var(--blue0);
  --color5: var(--blue5);
  --color10: var(--blue10);
  --color20: var(--blue20);
  --color30: var(--blue30);
  --color40: var(--blue40);
  --color50: var(--blue50);
  --color60: var(--blue60);
  --color70: var(--blue70);
  --color80: var(--blue80);
  --color90: var(--blue90);
  --color100: var(--blue100);

  --shadow2dp: var(--blueShadow2dp);
  --shadow4dp: var(--blueShadow4dp);
  --shadow8dp: var(--blueShadow8dp);
  --shadow16dp: var(--blueShadow16dp);
  --shadow24dp: var(--blueShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradBlue);
  --gradient-reverse: var(--gradBlueRev);
  /*-----*/
}

.color_danger__1tTXs {
  --color0: var(--red0);
  --color5: var(--red5);
  --color10: var(--red10);
  --color20: var(--red20);
  --color30: var(--red30);
  --color40: var(--red40);
  --color50: var(--red50);
  --color60: var(--red60);
  --color70: var(--red70);
  --color80: var(--red80);
  --color90: var(--red90);
  --color100: var(--red100);

  --shadow2dp: var(--redShadow2dp);
  --shadow4dp: var(--redShadow4dp);
  --shadow8dp: var(--redShadow8dp);
  --shadow16dp: var(--redShadow16dp);
  --shadow24dp: var(--redShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradRed);
  --gradient-reverse: var(--gradRedRev);
  /*-----*/
}

.color_success__hAar5 {
  --color0: var(--green0);
  --color5: var(--green5);
  --color10: var(--green10);
  --color20: var(--green20);
  --color30: var(--green30);
  --color40: var(--green40);
  --color50: var(--green50);
  --color60: var(--green60);
  --color70: var(--green70);
  --color80: var(--green80);
  --color90: var(--green90);
  --color100: var(--green100);

  --shadow2dp: var(--greenShadow2dp);
  --shadow4dp: var(--greenShadow4dp);
  --shadow8dp: var(--greenShadow8dp);
  --shadow16dp: var(--greenShadow16dp);
  --shadow24dp: var(--greenShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGreen);
  --gradient-reverse: var(--gradGreenRev);
  /*-----------------*/
}

.color_warning__1TcKM {
  --color0: var(--yellow0);
  --color5: var(--yellow5);
  --color10: var(--yellow10);
  --color20: var(--yellow20);
  --color30: var(--yellow30);
  --color40: var(--yellow40);
  --color50: var(--yellow50);
  --color60: var(--yellow60);
  --color70: var(--yellow70);
  --color80: var(--yellow80);
  --color90: var(--yellow90);
  --color100: var(--yellow100);

  --shadow2dp: var(--yellowShadow2dp);
  --shadow4dp: var(--yellowShadow4dp);
  --shadow8dp: var(--yellowShadow8dp);
  --shadow16dp: var(--yellowShadow16dp);
  --shadow24dp: var(--yellowShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradYellow);
  --gradient-reverse: var(--gradYellowRev);
  /*-----------------*/
}

.color_default__2SOOv {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/
}

.color_primary_alt__2zW1E {
  --color0: var(--indigo0);
  --color5: var(--indigo5);
  --color10: var(--indigo10);
  --color20: var(--indigo20);
  --color30: var(--indigo30);
  --color40: var(--indigo40);
  --color50: var(--indigo50);
  --color60: var(--indigo60);
  --color70: var(--indigo70);
  --color80: var(--indigo80);
  --color90: var(--indigo90);
  --color100: var(--indigo100);

  --shadow2dp: var(--indigoShadow2dp);
  --shadow4dp: var(--indigoShadow4dp);
  --shadow8dp: var(--indigoShadow8dp);
  --shadow16dp: var(--indigoShadow16dp);
  --shadow24dp: var(--indigoShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradIndigo);
  --gradient-reverse: var(--gradIndigoRev);
  /*-----*/
}

.color_danger_alt__3XKE6 {
  --color0: var(--pink0);
  --color5: var(--pink5);
  --color10: var(--pink10);
  --color20: var(--pink20);
  --color30: var(--pink30);
  --color40: var(--pink40);
  --color50: var(--pink50);
  --color60: var(--pink60);
  --color70: var(--pink70);
  --color80: var(--pink80);
  --color90: var(--pink90);
  --color100: var(--pink100);

  --shadow2dp: var(--pinkShadow2dp);
  --shadow4dp: var(--pinkShadow4dp);
  --shadow8dp: var(--pinkShadow8dp);
  --shadow16dp: var(--pinkShadow16dp);
  --shadow24dp: var(--pinkShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradPink);
  --gradient-reverse: var(--gradPinkRev);
  /*-----*/
}

.color_success_alt__2FJrI {
  --color0: var(--teal0);
  --color5: var(--teal5);
  --color10: var(--teal10);
  --color20: var(--teal20);
  --color30: var(--teal30);
  --color40: var(--teal40);
  --color50: var(--teal50);
  --color60: var(--teal60);
  --color70: var(--teal70);
  --color80: var(--teal80);
  --color90: var(--teal90);
  --color100: var(--teal100);

  --shadow2dp: var(--tealShadow2dp);
  --shadow4dp: var(--tealShadow4dp);
  --shadow8dp: var(--tealShadow8dp);
  --shadow16dp: var(--tealShadow16dp);
  --shadow24dp: var(--tealShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradTeal);
  --gradient-reverse: var(--gradTealRev);
  /*-----------------*/
}

.color_warning_alt__2uVZL {
  --color0: var(--orange0);
  --color5: var(--orange5);
  --color10: var(--orange10);
  --color20: var(--orange20);
  --color30: var(--orange30);
  --color40: var(--orange40);
  --color50: var(--orange50);
  --color60: var(--orange60);
  --color70: var(--orange70);
  --color80: var(--orange80);
  --color90: var(--orange90);
  --color100: var(--orange100);

  --shadow2dp: var(--orangeShadow2dp);
  --shadow4dp: var(--orangeShadow4dp);
  --shadow8dp: var(--orangeShadow8dp);
  --shadow16dp: var(--orangeShadow16dp);
  --shadow24dp: var(--orangeShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradOrange);
  --gradient-reverse: var(--gradOrangeRev);
  /*-----------------*/
}

/* END COLORS*/
/* ICON ANIMATION*/

/*.leftSidebar li[class*="selected"] > div:first-of-type > span[class*="icon"] {
  animation: shake 180ms ease-in;
}
@keyframes shake {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(-1);
  }
}*/

.demo_leftSidebar__3KUTg {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0.75rem;
  margin-left: 0.25rem;
}
.styles_footerBar__ul20V {
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.styles_text__1lYzL {
  font-weight: 700;
  color: var(--color-footer-text);
}

.styles_contactLinks__2trq6 {
  text-align: right;
  bottom: 10px;
}

.styles_contactLinks__2trq6>a {
  cursor: pointer;
}

.styles_contactLinks__2trq6>a+a {
  margin-left: 35px;

}

.styles_contact__3TCWB>p {
  font-weight: 400;
  margin-bottom: 0;
}

.styles_contact__3TCWB>p>a {
  cursor: pointer;
  margin-left: 10px;
  bottom: 0;
}

@media only screen and (max-width: 600px) {
  .styles_footerBar__ul20V {
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
  .styles_contactLinks__2trq6 {
    text-align: center;
  }
  .styles_contactLinks__2trq6 + p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
/* BUTTONS TYPES*/
/*--DEFAULT--*/
._type_default__w1w99 {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  letter-spacing: var(--letter-spacing-button);
  padding: 0 0.75rem 0 0.75rem;
}

._type_right__3Joop {
  border-radius: var(--s8) 0 0 var(--s8);
}
._type_left__3QLwH {
  border-radius: 0 var(--s8) var(--s8) 0;
}
._type_top__1xx9r {
  border-radius: 0 0 var(--s8) var(--s8);
}
._type_bottom__Uj3ez {
  border-radius: var(--s8) var(--s8) 0 0;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

/*SMOOTH STYLE*/
._view_smooth__1-0co {
  background: var(--white);
  box-shadow: var(--greyShadow8dp);
}

._view_smooth__1-0co span[class*="icon"] {
  color: var(--color70);
}
/* END STYLE */

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

/*body {
  overflow-y: scroll;
}*/
text {
  font-family: "Roboto";
}

.demo_style_NavBar__2zVf0 {
  display: flex;
  border-bottom: 1px solid #333333;
}

.demo_style_NavBar__2zVf0>div {
  padding: 10px;
  cursor: pointer;
}

.demo_style_NavBarContent__91GWp {
  padding: 10px;
}

.demo_style_contact_footer__2Mwd7 {
  font-weight: 400;
  margin-bottom: 10px;
}

/*MOBILE*/
.demo_style_container__27mF9 {
  height: 100vh;
  display: grid;
  grid-template-areas:
    "header"
    "."
    "content"
    "."
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 6rem 1rem auto 1rem 3rem;
}

.demo_style_header__31TB1 {
  background-color: #9c2b23;
  background: #9c2b23;
  color: #9c2b23;
}

.demo_style_sidebar__Q7KFJ {
  grid-area: sidebar;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 240px;
  z-index: 5;
  display: block;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 250ms ease-in-out;
  display: contents;
  /*overflow-y: auto;*/
  background: var(--grey0);
  border-radius: 0 0.5rem 0.5rem 0;
}

.demo_style_content__EJj8G {
  grid-area: content;
  margin-left: 0;
  margin-right: 0;
  overflow-y: unset;
}

.demo_style_content-component__3K0OD {
  display: grid;
  padding-right: 1rem;
  padding-left: 1rem;
  /* padding-bottom: 5rem;*/
}

.demo_style_right_sidebar__2vwSc {
  grid-area: props;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 0.25rem;
  background: var(--grey0);
  border-radius: 0.5rem 0 0 0.5rem;
}

.demo_style_footer__1koR2 {
  grid-area: footer;
  background-color: var(--grey0);
  padding: 0.625rem 0.5rem;
  letter-spacing: var(--letter-spacing-body);
  font-size: 0.875rem;
  display: inline-flex;
  position: relative;
  width: 100%;
  bottom: 0;
  height: 3rem;
  z-index: 9;
}

.demo_style_footer_links__3ZNT1 {
  color: var(--grey70);
  font-weight: 500;
  display: flex;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  margin-left: auto;
}

.demo_style_footer_links__3ZNT1 a {
  color: var(--grey70);
  text-decoration: none;
  margin-right: 1rem;
  text-align: center;
}

.demo_style_footer_links__3ZNT1 a:last-of-type {
  margin-right: 0;
}

.demo_style_footer_links__3ZNT1 a:hover {
  color: var(--blue70);
  text-decoration: none;
}

.demo_style_footer_break__30TON {
  display: block;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}

.demo_style_footer_text__ekN7X {
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
  color: var(--grey50);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}

.demo_style_props_button__2nviQ {
  position: fixed;
  bottom: 4rem;
  right: 1rem;
  z-index: 10;
}

.demo_style_nowrap__1yHhn {
  white-space: nowrap;
}

.demo_style_right_sidebar_backdrop__1SVMx {
  background-color: unset;
}

div[role="tablist"][class*="tab-list"] {
  margin-left: auto;
  margin-right: auto !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/*-----640PX-----*/
@media screen and (min-width: 640px) {
  .demo_style_container__27mF9 {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header"
      ". "
      "content "
      ". "
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1rem auto 1rem 3rem;
  }

  .demo_style_right_sidebar__2vwSc {
    /*display: grid;*/
    width: 180px;
    height: calc(100vh - 8rem);
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    top: 4.5rem;
    right: 0;
  }

  .demo_style_content-component__3K0OD {
    display: grid;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 5rem;
  }

  .demo_style_content__EJj8G {
    margin-right: 0;
    overflow-y: unset;
    width: calc(100% - 180px);
  }

  .demo_style_footer__1koR2 {
    padding: 0.25rem 0 0.25rem 1rem;
  }

  .demo_style_footer_text__ekN7X {
    width: auto;
    text-align: left;
    float: left;
    color: var(--grey50);
    font-size: var(--font_size_body);
    line-height: 24px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .demo_style_footer_break__30TON {
    display: inline-block;
    font-size: var(--font_size_body);
  }

  .demo_style_footer_links__3ZNT1 {
    padding-top: 0;
    padding-top: initial;
    float: right;
    font-size: var(--font_size_caption);
    letter-spacing: var(--letter_spacing_caption);
  }

  .demo_style_footer_links__3ZNT1,
  .demo_style_footer_links__3ZNT1 a {
    margin-right: 1.5rem;
    margin-bottom: auto;
    margin-top: auto;
  }

  .demo_style_footer_links__3ZNT1 svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  .demo_style_props_button__2nviQ {
    display: none;
  }
}

/*-----1024PX-----*/
@media screen and (min-width: 1024px) {
  .demo_style_container__27mF9 {
    height: 100vh;
    display: grid;
    grid-template-areas:
      "header header header"
      ". . ."
      "sidebar content props"
      ". . ."
      "footer footer footer";
    grid-template-columns: 240px 5fr 200px;
    grid-template-rows: 4.5rem 1rem auto 1rem 3rem;
  }

  .demo_style_sidebar__Q7KFJ {
    grid-area: sidebar;
    display: block;
    max-width: 240px;
    width: 240px;
    top: 4.5rem;
    -webkit-transform: unset;
            transform: unset;
    box-shadow: none;
    height: auto;
    background: none;
  }

  .demo_style_right_sidebar__2vwSc {
    width: 200px;
    height: calc(100vh - 9.5rem);
    top: 5.5rem;
    background: none;
    padding-bottom: 0;
    padding-top: 0;
  }

  .demo_style_content-component__3K0OD {
    display: grid;
    width: 100%;
    float: left;
  }

  .demo_style_content__EJj8G {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    padding: 0 1rem;
  }

  .demo_style_nowrap__1yHhn {
    white-space: normal;
    white-space: initial;
  }
}

@media screen and (min-width: 1440px) {
  .demo_style_container__27mF9 {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header header"
      ". . ."
      "sidebar content"
      ". . ."
      "footer footer";
    grid-template-columns: 200px 1fr;
    grid-template-rows: 4.5rem 1rem auto 0 3rem;
  }

  .demo_style_sidebar__Q7KFJ {
    width: 200px;
    max-width: 200px;
  }

  .demo_style_content__EJj8G {
    padding: 0;
    padding-left: 0.75rem;
  }

  .demo_style_content-component__3K0OD {
    width: calc(100% - 200px);
    padding: 0;
  }

  .demo_style_right_sidebar__2vwSc {
    position: relative;
    top: 0;
  }

  .demo_style_footer__1koR2 {
    position: absolute;
    left: 0;
  }
}
.container{display:grid;grid-template-areas:"header " "content";grid-template-columns:fit-content(100%);padding-top:1em;padding-left:20px;padding-right:20px}.breadcrumbs{grid-area:header;top:4.5rem}.content{grid-area:content;padding-top:2.5em}.form{display:flex;flex-direction:column;max-width:700px;margin:auto;text-align:center}.button{color:#61dafb}.icon{justify-content:left;background:#00a6e9;text-align:left;max-width:180px}.textField{position:relative;float:left;width:"100%";margin-right:30px}
:local(.labelStyles) label{font-weight:bold;font-size:14px;margin-bottom:10px;display:block;text-align:left}:local(.inputStyles) input{margin-bottom:20px;width:100%;padding:10px;height:10px}
.container{display:grid;grid-template-areas:"header " "content";grid-template-columns:fit-content(100%);padding-top:1em;padding-left:20px;padding-right:20px}.breadcrumbs{grid-area:header;top:4.5rem}.content{grid-area:content;padding-top:2.5em}.form{display:flex;flex-direction:column;max-width:700px;margin:auto;text-align:center}.button{color:#61dafb}.icon{justify-content:left;background:#00a6e9;text-align:left;max-width:180px}.textField{position:relative;float:left;width:"100%";margin-right:30px}
.container{display:grid;grid-template-areas:"header " "content";grid-template-columns:fit-content(100%);padding-top:1em;padding-left:20px;padding-right:20px}.table{min-width:700}.image{width:100%}.root{margin:10;overflow-x:"auto"}.breadcrumbs{grid-area:header;top:4.5rem}.content{grid-area:content;padding-top:2.5em}.form{display:flex;flex-direction:column;max-width:700px;margin:auto;text-align:center}.button{color:#61dafb}.icon{justify-content:left;background:#00a6e9;text-align:left;max-width:180px}.textField{position:relative;float:left;width:"100%";margin-right:30px}.image{width:40;height:40}

.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}.icon{width:20;height:20;color:#fff;font-size:10px}.link{display:"flex";color:#fff;font-size:18px}.linkicon{color:#fff;font-size:15px}@media(prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.main-wrapper{position:relative;min-height:100vh}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}
.style_container__14UUx {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__2mCh2 {
  padding-top: 1rem;
}

.style_h4_title__2dCBc {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__2qSR- {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__3b-Ub {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_link__3HH1L,
.style_link__3HH1L:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__3HH1L:hover {
  color: var(--blue70);
}

.style_promo_group__1DU4n {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__fDVex {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__2E0G2 {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__29R4d {
  padding-right: 5rem;
  width: 25%;

}

.style_item_img__1tRxa {
  padding-right: 5rem;
  width: 50%;
  height: 50%
}

@media screen and (min-width: 640px) {
  .style_promo_group__1DU4n {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__fDVex {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.style_container__1u4QS {
  padding-right: 1rem;
  padding-left: 1rem;
  font-family: var(--Acumin)
}

.style_sub_title__tFvl6 {
  padding-top: 1rem;
}

.style_h4_title__1PtHl {

  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_h5_title__2x1mE {

  padding-right: 5rem;
  font-family: var(--Acumin);

}

.style_text__3t7Eb {
  padding-top: 0.5rem;
  padding-right: 5rem;
  font-family: var(--Acumin);
}

.style_notebook__2H-EC {
  padding-top: 0.5rem;
  padding-right: 5rem;

}

.style_link__Pi_ha,
.style_link__Pi_ha:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}

.style_link__Pi_ha:hover {
  color: var(--blue70);
}

.style_promo_group__jzZfq {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.style_promo_item__2B3ts {
  width: 100%;
  padding-bottom: 1.5rem;
}

.style_item_img_container__2t2Bb {
  border-radius: 0.5rem;
  padding: 0.25rem;

}

.style_item_logo__2FgPV {
  padding-right: 5rem;
  width: 29%;

}

.style_item_img__2SJW1 {
  padding-right: 5rem;
  width: 100%;

}

@media screen and (min-width: 640px) {
  .style_promo_group__jzZfq {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .style_promo_item__2B3ts {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}#container{display:"flex";align-items:"baseline";text-align:center}@media(prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.main-wrapper{position:relative;min-height:100vh}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
.container{display:grid;grid-template-areas:"header " "content";grid-template-columns:fit-content(100%);padding-top:1em;padding-left:20px;padding-right:20px}.breadcrumbs{grid-area:header;top:4.5rem}.content{grid-area:content;padding-top:2.5em}.form{display:flex;flex-direction:column;max-width:700px;margin:auto;text-align:center}.button{color:#61dafb}.icon{justify-content:left;background:#00a6e9;text-align:left;max-width:180px}.textField{position:relative;float:left;width:"100%";margin-right:30px}
:local(.styles) button{color:#fff}
.style_layout__1FplQ {
  display: flex;
  background: transparent;
  border: 1px solid var(--grey20);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.style_grid_item__WMCQx {
  display: flex;
  flex-direction: column;
  /* padding: 40px 0; */
  align-items: center;
  border-radius: 1rem;
  background: transparent;
}
.style_grid_item__WMCQx:hover {
  cursor: pointer;
  background: var(--color10);
  transition: all 0.25s ease-in-out;
}

.style_item__icon__2Qu1V {
  width: 6.5em;
  height: 6.5em;
  background: var(--color50);
  border-radius: 1rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
}
.style_item__title__34hXc {
  text-align: center;
  margin-top: 1.5rem;
  white-space: pre-wrap;
}

@media (min-width: 540px) {
  .style_layout__1FplQ {
    justify-content: center;
  }
}

/* TYPOGRAPHY TYPES*/

._type_roboto__pPqc3 {
  font-family: var(--roboto);
}
._type_inter__1y3l- {
  font-family: var(--inter);
}
._type_lato__--mY1 {
  font-family: var(--lato);
}
._type_plex__2TDfj {
  font-family: var(--plex);
}
._type_manrope__1f-mm {
  font-family: var(--manrope);
}

._type_h1__3bkwz {
  font-weight: var(--font_weight_h1);
  font-size: var(--font_size_h1);
  letter-spacing: var(--letter_spacing_h1);
  line-height: 120%;
}

._type_h2__1yqwS {
  font-weight: var(--font_weight_h2);
  font-size: var(--font_size_h2);
  letter-spacing: var(--letter_spacing_h2);
  line-height: 120%;
}

._type_h3__1RQQe {
  font-weight: var(--font_weight_h3);
  font-size: var(--font_size_h3);
  letter-spacing: var(--letter_spacing_h3);
  line-height: 124%;
}

._type_h4__avvqw {
  font-weight: var(--font_weight_h4);
  font-size: var(--font_size_h4);
  letter-spacing: var(--letter_spacing_h4);
  line-height: 128%;
}

._type_h5__28pFg {
  font-weight: var(--font_weight_h5);
  font-size: var(--font_size_h5);
  letter-spacing: var(--letter_spacing_h5);
  line-height: 150%;
}

._type_h6__2OSJk {
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  line-height: 150%;
}

._type_body__33MYb {
  font-weight: var(--font_weight_body);
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  line-height: 150%;
}
._type_body_bold__20dhA {
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_bold);
}
._type_body__33MYb._type_large__16f0w {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large);
  line-height: 158%;
}
._type_body_bold__20dhA._type_large__16f0w {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large_bold);
  line-height: 158%;
}
._type_body__33MYb._type_small__2DEMM,
._type_body_bold__20dhA._type_small__2DEMM {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  line-height: 143%;
}
._type_button_text__2fmIB {
  font-weight: var(--font_weight_button);
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  line-height: 114%;
}

._type_caption__2KVRo {
  font-weight: var(--font_weight_caption);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  line-height: 126%;
}
._type_caption__2KVRo._type_small__2DEMM {
  font-size: var(--font_size_caption_small);
  font-weight: var(--font_weight_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  line-height: 120%;
}

._type_overline__PBk2q {
  font-weight: var(--font_weight_overline);
  font-size: var(--font_size_overline);
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
  line-height: 126%;
}
._type_overline__PBk2q._type_small__2DEMM {
  font-size: var(--font_size_overline_small);
  letter-spacing: var(--letter_spacing_overline_small);
  line-height: 120%;
}

._view_color0__38yEe {
  color: var(--color0);
}
._view_color5__33bEu {
  color: var(--color5);
}
._view_color10__3Zxzg {
  color: var(--color10);
}
._view_color20__3ucTF {
  color: var(--color20);
}
._view_color30__1vWt0 {
  color: var(--color30);
}
._view_color40__3g7PT {
  color: var(--color40);
}
._view_color50__9pSQs {
  color: var(--color50);
}
._view_color60__3VnPm {
  color: var(--color60);
}
._view_color70__V2TK1 {
  color: var(--color70);
}
._view_color80__225l9 {
  color: var(--color80);
}
._view_color90__22jO6 {
  color: var(--color90);
}
._view_color100__1Tsmq {
  color: var(--color100);
}

/**TOAST*/
._type_def__1EOYl > div {
  height: auto;
  border-radius: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  z-index: 999;
}
._type_def__1EOYl span[class*="toast-message"] {
  padding: 0;
  margin: auto;
  margin-left: 1rem;
  font-size: var(--font_size_h6);
  font-family: var(--font_secondary);
  letter-spacing: var(--letter_spacing_h6);
  font-weight: bold;
}
._type_def__1EOYl div[class*="button-group"] {
  padding: 0;
  margin: auto;
  margin-right: 1rem;
}
._type_def__1EOYl div[class*="button-group"] svg {
  width: 1.5rem;
  height: 1.5rem;
}
._type_def__1EOYl div[class*="button-group"] a {
  margin-left: 0.5rem;
  font-family: var(--font_primary);
  text-transform: uppercase;
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  font-weight: var(--font_weight_button);
}

._type_def__1EOYl span[class*="icon"]:first-child {
  float: left;
  margin: auto;
  border-radius: 99px;
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}
._type_def__1EOYl span[class*="icon"]:first-child > svg {
  margin: auto;
  margin-top: 0.5rem;
}

._type_def__1EOYl._type_no-close__JhhoM div[class*="button-group"] button[class*="button"] {
  display: none;
}

/*TOAST*/

/*flat*/

/*filled*/

._view_filled__1SEQH > div {
  background-color: var(--color70);
  color: var(--color10);
  box-shadow: none;
}
._view_filled__1SEQH div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_filled__1SEQH div[class*="button-group"] > button:hover svg,
._view_filled__1SEQH div[class*="button-group"] > button[class*="button"]:hover,
._view_filled__1SEQH div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color10);
  background: none;
  transition: all 180ms ease-in;
}
._view_filled__1SEQH div[class*="button-group"] a {
  color: var(--color30);
  transition: all 180ms ease-in;
}

._view_filled__1SEQH svg,
._view_filled__1SEQH span[class*="icon"]:first-child {
  color: var(--color10);
}

._view_filled__1SEQH div > span[class*="icon"]:first-child {
  background: var(--color80);
}

/*smooth*/

._view_smooth__2vvZA > div {
  background-color: var(--color5);
  color: var(--color80);
  box-shadow: none;
}
._view_smooth__2vvZA div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_smooth__2vvZA div[class*="button-group"] > button:hover svg,
._view_smooth__2vvZA div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
._view_smooth__2vvZA div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--white);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  box-shadow: var(--shadow4dp);
  transition: all 180ms ease-in;
}
._view_smooth__2vvZA div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--white);
  box-shadow: var(--shadow8dp);
}
._view_smooth__2vvZA div[class*="button-group"] a[class*="button"]:active {
  box-shadow: var(--shadow2dp);
}

._view_smooth__2vvZA svg,
._view_smooth__2vvZA span[class*="icon"]:first-child {
  color: var(--color80);
}

._view_smooth__2vvZA div > span[class*="icon"]:first-child {
  background: var(--color20);
}

/*OUTLINED*/

._view_outlined__1RTZo > div {
  background-color: var(--white);
  color: var(--color80);
  border: 1px solid var(--color30);
  box-shadow: none;
}
._view_outlined__1RTZo div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_outlined__1RTZo div[class*="button-group"] > button:hover svg,
._view_outlined__1RTZo div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
/*close button*/
._view_outlined__1RTZo div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--color5);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  /*box-shadow: var(--shadow4dp);*/
  transition: all 180ms ease-in;
}
._view_outlined__1RTZo div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--color5);
  box-shadow: var(--shadow4dp);
}
._view_outlined__1RTZo div[class*="button-group"] a[class*="button"]:active {
  box-shadow: none;
  background: var(--color10);
}
/**/

/*icon*/
._view_outlined__1RTZo svg,
._view_outlined__1RTZo span[class*="icon"]:first-child {
  color: var(--color80);
}

._view_outlined__1RTZo div > span[class*="icon"]:first-child {
  background: var(--color10);
}

/*RAISED*/

._view_raised__2No6y > div {
  background-color: var(--white);
  color: var(--color80);
  box-shadow: var(--shadow4dp);
}
._view_raised__2No6y div[class*="button-group"] svg {
  color: var(--grey50);
  transition: all 180ms ease-in;
}
._view_raised__2No6y div[class*="button-group"] > button:hover svg,
._view_raised__2No6y div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--grey90);
  background: none;
  transition: all 180ms ease-in;
}
/*close button*/
._view_raised__2No6y div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--color5);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  /*box-shadow: var(--shadow4dp);*/
  transition: all 180ms ease-in;
}
._view_raised__2No6y div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--color5);
  box-shadow: var(--shadow4dp);
}
._view_raised__2No6y div[class*="button-group"] a[class*="button"]:active {
  box-shadow: none;
  background: var(--color10);
}
/*icon*/
._view_raised__2No6y svg,
._view_raised__2No6y span[class*="icon"]:first-child {
  color: var(--color80);
}
._view_raised__2No6y div > span[class*="icon"]:first-child {
  background: var(--color10);
}

.style_layout__2Uqzn {
  display: flex;
  background: transparent;
  border: 1px solid var(--grey20);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.style_grid_item__eU1Xj {
  display: flex;
  flex-direction: column;
  /* padding: 40px 0; */
  align-items: center;
  border-radius: 1rem;
  background: transparent;
}
.style_grid_item__eU1Xj:hover {
  cursor: pointer;
  background: var(--color10);
  transition: all 0.25s ease-in-out;
}

.style_item__icon__3eep3 {
  width: 6.5em;
  height: 6.5em;
  background: var(--color50);
  border-radius: 1rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
}
.style_item__title__D8Vzd {
  text-align: center;
  margin-top: 1.5rem;
  white-space: pre-wrap;
}

@media (min-width: 540px) {
  .style_layout__2Uqzn {
    justify-content: center;
  }
}

._type_dropdown__2ucxC,
._type_dropdown--dense__3YqQJ,
._type_dropdown__2ucxC:after,
._type_dropdown--dense__3YqQJ::after {
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

._type_dropdown__2ucxC {
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}
._type_dropdown--dense__3YqQJ {
  height: 1.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: var(--font_size_body_small);
}

._type_dropdown--dense__3YqQJ::after,
._type_dropdown__2ucxC::after {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: 0.1em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.35em solid transparent;
  border-bottom: 0;
  border-left: 0.35em solid transparent;
  border-radius: 0.125rem;
}

._type_dropdown__2ucxC:hover,
._type_dropdown--dense__3YqQJ:hover {
  cursor: pointer;
}

._view_popover__3kUVj[class*="minimal"] {
  box-shadow: none;
  margin-top: 4px !important;
  background: transparent;
}
._view_popover__3kUVj > div[class*="popover-content"] {
  background: transparent;
}
/*CARET-DOWN*/
._view_outlined__141-3::after,
._view_raised__3S5H-::after,
._view_smooth__jtY1Q::after {
  color: var(--color40);
}
._view_outlined__141-3:hover::after,
._view_raised__3S5H-:hover::after,
._view_smooth__jtY1Q:hover::after {
  color: var(--color60);
}
._view_outlined__141-3:active::after,
._view_outlined__141-3:focus::after,
._view_raised__3S5H-:active::after,
._view_raised__3S5H-:focus::after,
._view_smooth__jtY1Q:active::after,
._view_smooth__jtY1Q:focus::after {
  color: var(--color90);
}
._view_filled__zGpS8::after {
  color: var(--color10);
}
._view_filled__zGpS8:hover::after {
  color: var(--color5);
}
._view_filled__zGpS8:active::after,
._view_filled__zGpS8:focus::after {
  color: var(--color0);
}
/**/

/*OUTLINED DROPDOWN*/
._view_outlined__141-3 {
  margin: 2px;
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: 0 0 0 1px var(--color30);
}
._view_outlined__141-3:hover {
  box-shadow: 0 0 0 1px var(--color80);
}
._view_outlined__141-3:active,
._view_outlined__141-3:focus {
  box-shadow: 0 0 0 2px var(--color80);
}

/* RAISED DROPDOWN*/
._view_raised__3S5H- {
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow2dp);
}
._view_raised__3S5H-:hover {
  box-shadow: var(--shadow4dp);
}
._view_raised__3S5H-:active,
._view_raised__3S5H-:focus {
  box-shadow: var(--shadow8dp);
}

/* SMOOTH DROPDOWN*/
._view_smooth__jtY1Q {
  border: none;
  color: var(--color100);
  background: var(--color5);
}
._view_smooth__jtY1Q:hover {
  background: var(--color10);
}
._view_smooth__jtY1Q:active,
._view_smooth__jtY1Q:focus {
  background: var(--color20);
}

/* FILLED DROPDOWN*/
._view_filled__zGpS8 {
  border: none;
  color: var(--color0);
  background: var(--color40);
}
._view_filled__zGpS8:hover {
  background: var(--color50);
}
._view_filled__zGpS8:active,
._view_filled__zGpS8:focus {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
}

/**CARD*/

._type_def__2Hdyr {
  border-radius: 0.5rem;
  padding: 0;
}

/*CARD*/
/*filled*/
._view_filled__3mHmE {
  background-color: var(--color50);
  border: none;
  box-shadow: var(--shadow2dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__3mHmE[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  background-color: var(--color70);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__3mHmE[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  background-color: var(--color50);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*smooth*/
._view_smooth__3XQ9J {
  background-color: var(--color0);
  border: none;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__3XQ9J[class*="interactive"]:hover {
  background-color: var(--color10);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__3XQ9J[class*="interactive"]:active {
  box-shadow: none;
  background-color: var(--color0);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*outlined*/
._view_outlined__3vG0P {
  background-color: var(--white);
  border: 1px solid var(--color20);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__3vG0P[class*="interactive"]:hover {
  border: 1px solid var(--color30);
  box-shadow: 0 0 0 1px var(--color30);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__3vG0P[class*="interactive"]:active {
  box-shadow: none;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
/*raised*/
._view_raised__3fMwV {
  background-color: var(--white);
  box-shadow: var(--shadow2dp);
  border: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__3fMwV[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__3fMwV[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*MENU SHAPE CSS MODULE*/
/*DEFAULT*/
._type_def__kOVk-,
._type_def__kOVk- div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.5rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._type_def__kOVk- div[class*="popover-content"] {
  border-radius: 0.5rem;
}
._type_def__kOVk- > li:not([class*="menu-divider"]),
._type_def__kOVk-
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 2.5rem;
  margin: 0;
}
._type_def__kOVk- > li:first-of-type,
._type_def__kOVk- div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 3rem;
  height: 3rem;
}
._type_def__kOVk- > li:last-of-type,
._type_def__kOVk- div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 3rem;
}
._type_def__kOVk- div[class*="popover"][class*="submenu"],
._type_dense__2I7Yd div[class*="popover"][class*="submenu"] {
  padding: 0;
  margin-left: -0.0625rem;
}
._type_def__kOVk- div[class*="popover"][class*="submenu"],
._type_dense__2I7Yd div[class*="popover"][class*="submenu"] {
  -webkit-animation: _type_slide-fwd-right__1XAgS 0.28s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: _type_slide-fwd-right__1XAgS 0.28s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Heading divider*/
._type_def__kOVk- > li[class*="menu-header"]:not([class*="menu-divider"]),
._type_dense__2I7Yd > li[class*="menu-header"]:not([class*="menu-divider"]) {
  margin-top: 0.5rem;
}
/*----*/

/*DENSE*/
._type_dense__2I7Yd,
._type_dense__2I7Yd div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.375rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}

._type_dense__2I7Yd div[class*="popover-content"] {
  border-radius: 0.375rem;
}
._type_dense__2I7Yd > li:not([class*="menu-divider"]),
._type_dense__2I7Yd
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 1.75rem;
  margin: 0;
}
._type_dense__2I7Yd > li:first-of-type,
._type_dense__2I7Yd div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 2.25rem;
  height: 2.25rem;
}
._type_dense__2I7Yd > li:last-of-type,
._type_dense__2I7Yd div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 2.25rem;
}

/*-------------------*/

@-webkit-keyframes _type_slide-fwd-right__1XAgS {
  0% {
    -webkit-transform: scale(0.9) translateX(-20%);
            transform: scale(0.9) translateX(-20%);
  }
  100% {
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
}

@keyframes _type_slide-fwd-right__1XAgS {
  0% {
    -webkit-transform: scale(0.9) translateX(-20%);
            transform: scale(0.9) translateX(-20%);
  }
  100% {
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
}

/*MENU VIEW*/
/*SMOOTH*/
._view_smooth__2q38s,
._view_smooth__2q38s div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow2dp);
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__2q38s div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_smooth__2q38s svg {
  color: var(--color70);
}
._view_smooth__2q38s > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_smooth__2q38s > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--white);
}
._view_smooth__2q38s a[class*="disabled"] > div,
._view_smooth__2q38s a > span[class*="label"],
._view_smooth__2q38s a[class*="disabled"] > span[class*="item-label"],
._view_smooth__2q38s a[class*="disabled"] svg,
._view_smooth__2q38s a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_smooth__2q38s
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}

/*OUTLINED*/
._view_outlined__2LnaI,
._view_outlined__2LnaI div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: none;
  color: var(--color100);
  background: var(--white);
  border: 0.125rem solid var(--color30);
}
._view_outlined__2LnaI div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_outlined__2LnaI svg {
  color: var(--color70);
}
._view_outlined__2LnaI > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_outlined__2LnaI > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color5);
}
._view_outlined__2LnaI a[class*="disabled"] > div,
._view_outlined__2LnaI a > span[class*="label"],
._view_outlined__2LnaI a[class*="disabled"] > span[class*="item-label"],
._view_outlined__2LnaI a[class*="disabled"] svg,
._view_outlined__2LnaI a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_outlined__2LnaI
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}
/*-----*/
/*RAISED*/
._view_raised__1_9di,
._view_raised__1_9di div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow8dp);
  color: var(--color100);
  background: var(--white);
}
._view_raised__1_9di div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_raised__1_9di svg {
  color: var(--color70);
}
._view_raised__1_9di > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_raised__1_9di > li[class*="menu-divider"] {
  border-top-color: var(--color10);
}
._view_raised__1_9di > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color5);
}
._view_raised__1_9di a[class*="disabled"] > div,
._view_raised__1_9di a > span[class*="label"],
._view_raised__1_9di a[class*="disabled"] > span[class*="item-label"],
._view_raised__1_9di a[class*="disabled"] svg,
._view_raised__1_9di a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_raised__1_9di
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}
/*-----*/
/*FILLED*/
._view_filled__COko6,
._view_filled__COko6 div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow8dp);
  color: var(--color0);
  background: var(--color70);
}
._view_filled__COko6 div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_filled__COko6 svg {
  color: var(--color40);
}
._view_filled__COko6 > li:not([class*="menu-divider"]) a {
  background-color: transparent;
}
._view_filled__COko6 > li[class*="menu-divider"] {
  border-top-color: var(--color80);
}
._view_filled__COko6 > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color90);
}
._view_filled__COko6 a[class*="disabled"] > div,
._view_filled__COko6 a > span[class*="label"],
._view_filled__COko6 a[class*="disabled"] > span[class*="item-label"],
._view_filled__COko6 a[class*="disabled"] svg,
._view_filled__COko6 a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_filled__COko6
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color90);
}

/*MENU ITEM CSS MODULE*/
/*DEFAULT*/
._type_def__1cLO9,
._type_dense__1n5BZ {
  line-height: 2.5rem;
  padding: 0;
  margin: 0 0.5rem;
  border-radius: 0.25rem;
}
._type_def__1cLO9 > div[class*="fill"] {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
._type_def__1cLO9 > span[class*="icon"],
._type_dense__1n5BZ > span[class*="icon"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 0.75rem;
  margin-right: -0.25rem;
}

._type_def__1cLO9 > span[class*="menu-item-label"],
._type_dense__1n5BZ > span[class*="menu-item-label"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
._type_def__1cLO9 > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
._type_def__1cLO9 > span[class*="menu-item-label"] > span[class*="icon"],
._type_dense__1n5BZ > span[class*="menu-item-label"] > span[class*="icon"] {
  display: block;
}

/*DENSE*/
._type_dense__1n5BZ {
  line-height: 1.75rem;
}
._type_dense__1n5BZ > div[class*="fill"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
._type_dense__1n5BZ > span[class*="icon"] {
  margin-left: 0.5rem;
  margin-right: -0.125rem;
}
._type_dense__1n5BZ > span[class*="menu-item-label"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
/*--------------*/
._type_def__1cLO9 > span[class*="icon-caret-right"],
._type_dense__1n5BZ > span[class*="icon-caret-right"] {
  margin-right: 0.5rem;
}

/*MENU ITEM*/
._view_smooth__NBYoq._view_note__1tW_p,
._view_outlined__3bjW2._view_note__1tW_p,
._view_raised__2ufDY._view_note__1tW_p {
  color: var(--color80);
}
._view_filled__cvlON._view_note__1tW_p {
  color: var(--color10);
}

/*MENU DIVIDER SHAPE CSS MODULE*/

._type_def__1Wl3P,
._type_dense__aBayN {
  margin: 0.5rem;
  padding: 0;
}
._type_def__1Wl3P > h6 {
  line-height: 2.5rem;
  padding: 0;
  margin-left: 0.5rem;
}

._type_dense__aBayN > h6 {
  line-height: 1.75rem;
  padding: 0;
  margin-left: 0.5rem;
}

/*MENU DIVIDER VIEW*/

._view_smooth__2B5je > h6,
._view_outlined__1B-wE > h6 {
  color: var(--color80);
}
._view_filled__gXjWD > h6 {
  color: var(--color30);
}

._view_smooth__2B5je,
._view_outlined__1B-wE {
  border-top: 0.0625rem solid var(--color10);
}

.style_container__3-BEh {
  margin: 2.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_cards_row__2BYvH,
.style_cards_row_last__3m7uN {
  display: flex;
  flex: 1 1 auto;
  margin: 1rem;
}
.style_cards_row_last__3m7uN {
  justify-content: space-between;
}
.style_cards_row_last__3m7uN > div:not(:last-child) {
  margin-right: 1rem;
}

.style_item__icon__jWav9 {
  color: var(--color40);
  justify-content: flex-start;
  display: flex;
  margin-bottom: 1rem;
}
.style_card_row_add__2PCZL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 290px; */
  margin: 1rem 3rem;
}
.style_card_row_add__2PCZL > div {
  margin-bottom: 0.5rem;
}
.style_dialog__1dCRM div[class*="footer"] {
  flex-direction: column;
}
.style_dialog__1dCRM div[class*="footer"] button {
  width: 100%;
}
.style_dialog__1dCRM div[class*="right-button"] {
  margin-top: 1.5rem;
}

@media (min-width: 640px) {
  .style_cards_row__2BYvH > div:not(:last-child) {
    margin-right: 1rem;
  }
}

.style_container__3eEsC {
  margin: 2.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_cards_row__1oZ24,
.style_cards_row_last__3ZEf5 {
  display: flex;
  flex: 1 1 auto;
  margin: 1rem;
}
.style_cards_row_last__3ZEf5 {
  justify-content: space-between;
}
.style_cards_row_last__3ZEf5 > div:not(:last-child) {
  margin-right: 1rem;
}

.style_item__icon__1Dtbo {
  color: var(--color40);
  justify-content: flex-start;
  display: flex;
  margin-bottom: 1rem;
}
.style_card_row_add__3WSBc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 290px; */
  margin: 1rem 3rem;
}
.style_card_row_add__3WSBc > div {
  margin-bottom: 0.5rem;
}
.style_dialog__20uE2 div[class*="footer"] {
  flex-direction: column;
}
.style_dialog__20uE2 div[class*="footer"] button {
  width: 100%;
}
.style_dialog__20uE2 div[class*="right-button"] {
  margin-top: 1.5rem;
}

@media (min-width: 640px) {
  .style_cards_row__1oZ24 > div:not(:last-child) {
    margin-right: 1rem;
  }
}

/* DIALOG TYPES*/
/*--DEFAULT--*/
._type_default__1TM2y {
  width: 98%;
  max-width: 30.5rem;
  padding: 2.5rem;
  border-radius: 1.5rem;
}

._type_header__NslZv {
  display: flex;
  margin-bottom: 1rem;
}
._type_header__NslZv > span[class*="icon"] {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
}
._type_footer__25hLX {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}
._type_left-button__2BfSJ {
  /* float: left; */
}
._type_right-button__3lre5 {
  /* float: right; */
}

._type_dense__33ZjN {
  width: 98%;
  max-width: 20.5rem;
  padding: 1rem;
  border-radius: 1rem;
}
._type_dense__33ZjN._type_header__NslZv {
  margin-bottom: 0.75rem;
}
._type_dense__33ZjN._type_header__NslZv > span[class*="icon"] {
  margin-right: 0.75rem;
}
._type_dense__33ZjN._type_footer__25hLX {
  margin-top: 1.75rem;
}

._type_close-button__2r4x_ {
  margin-right: 0;
  margin-left: auto;
}

/*DIALOG STYLE*/

/*SMOOTH*/
._view_smooth__15l3G {
  background: var(--color5);
  box-shadow: none;
}
/*RAISED*/
._view_raised__2mPtz {
  background: var(--white);
  box-shadow: var(--shadow24dp);
}
/*FILLED*/
._view_filled__dLmgw {
  background: var(--color50);
  box-shadow: none;
}
._view_filled__dLmgw > div[class*="header"] > div,
._view_filled__dLmgw > div[class*="body"] {
  color: var(--color0);
}
/*ICON*/
._view_filled__dLmgw > div[class*="header"] > span[class*="icon"] > svg,
._view_raised__2mPtz > div[class*="header"] > span[class*="icon"] > svg,
._view_smooth__15l3G > div[class*="header"] > span[class*="icon"] > svg {
  fill: var(--color30);
}
/*CLOSE_BUTTON*/
._view_filled__dLmgw > div[class*="header"] div[class*="close-button"] svg,
._view_raised__2mPtz > div[class*="header"] div[class*="close-button"] svg,
._view_smooth__15l3G > div[class*="header"] div[class*="close-button"] svg {
  fill: var(--color30);
  transition: all 150ms ease-in-out;
}

._view_raised__2mPtz > div[class*="header"] div[class*="close-button"]:hover svg,
._view_smooth__15l3G > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color60);
  transition: all 150ms ease-in-out;
}
._view_filled__dLmgw > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color5);
  transition: all 150ms ease-in-out;
}
._view_filled__dLmgw > div[class*="header"] div[class*="close-button"]:hover,
._view_raised__2mPtz > div[class*="header"] div[class*="close-button"]:hover,
._view_smooth__15l3G > div[class*="header"] div[class*="close-button"]:hover {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

/*BACKDROP STYLE*/
._view_backdrop-0__3naxQ {
  background-color: rgba(25, 25, 29, 0);
}
._view_backdrop-10__-wM2q {
  background-color: rgba(25, 25, 29, 0.1);
}
._view_backdrop-20__37PAM {
  background-color: rgba(25, 25, 29, 0.2);
}
._view_backdrop-30__1gpwH {
  background-color: rgba(25, 25, 29, 0.3);
}
._view_backdrop-40__2RKdG {
  background-color: rgba(25, 25, 29, 0.4);
}
._view_backdrop-50__1glyM {
  background-color: rgba(25, 25, 29, 0.5);
}
._view_backdrop-60__28KS6 {
  background-color: rgba(25, 25, 29, 0.6);
}
._view_backdrop-70__3jfQg {
  background-color: rgba(25, 25, 29, 0.7);
}
._view_backdrop-80__2V0kq {
  background-color: rgba(25, 25, 29, 0.8);
}
._view_backdrop-90__1gcDc {
  background-color: rgba(25, 25, 29, 0.9);
}
._view_backdrop-100__3nMai {
  background-color: rgba(25, 25, 29, 1);
}
/* END STYLE */

/* BUTTONS TYPES*/
/*--DEFAULT--*/
._type_default__-xtIn {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  text-align: center;
  letter-spacing: var(--letter-spacing-button);
  min-width: var(--s40);
  height: var(--s40);
  min-height: unset;
  padding: 0 1rem 0 1rem;
  line-height: var(--s16);
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_default__-xtIn._type_dense__1fwmI {
  font-weight: normal;
  letter-spacing: var(--letter_spacing_body_small);
  height: var(--s28);
  padding: 0 0.75rem 0 0.75rem;
}
/*--card--*/
._type_card__eEh5y {
  font-size: var(--font_size_caption);
  font-family: var(--font_family_button);
  line-height: var(--s16);
  text-align: center;
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  min-width: var(--s80);
  height: var(--s56);
  display: inline;
  display: initial;
  word-break: break-all;
  padding: var(--s8) 0;
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_card__eEh5y p {
  margin: 0;
}
._type_card__eEh5y > span {
  margin-right: 0;
}
._type_card__eEh5y > span[class*="icon"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-min-content;
  width: min-content;
  margin-bottom: 0.25rem;
}
._type_card__eEh5y[class*="button"] > div[class*="spinner"] {
  position: static;
  position: initial;
  margin-top: 0.625rem;
}
._type_dense__1fwmI._type_card__eEh5y {
  min-width: var(--s56);
  height: var(--s40);
  padding: var(--s6) 0;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
}
._type_dense__1fwmI._type_card__eEh5y > span[class*="icon"] {
  margin-bottom: 0;
}
._type_dense__1fwmI._type_card__eEh5y[class*="button"] > div[class*="spinner"] {
  margin-top: 0.375rem;
}
/*--circle--*/
._type_circle__PKM6U {
  height: var(--s56);
  width: var(--s56);
  border-radius: 99px;
}

._type_dense__1fwmI._type_circle__PKM6U {
  height: var(--s40);
  width: var(--s40);
}
/*--ACTION--*/
._type_action__2RB2f {
  font-family: var(--font_family_button);
  font-style: normal;
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  height: var(--s56);
  border-radius: 99px;
  padding: 0.75rem 1rem;
  text-transform: capitalize;
}
._type_action__2RB2f span {
  margin: 0;
}
._type_action__2RB2f span:first-child[class*="icon"] {
  text-align: left;
  margin-right: 0.75rem;
}
._type_action__2RB2f span:last-child[class*="icon"] {
  margin-left: 0.75rem;
}
._type_dense__1fwmI._type_action__2RB2f {
  font-weight: normal;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body_bold);
  height: var(--s40);
  border-radius: 99px;
  padding: 0.5rem 0.75rem;
}

._type_dense__1fwmI._type_action__2RB2f span:first-child[class*="icon"] {
  margin-right: 0.625rem;
}
._type_dense__1fwmI._type_action__2RB2f span:last-child[class*="icon"] {
  margin-left: 0.625rem;
}
/*--ICON--*/
._type_icon__2zHO7 {
  width: var(--s36);
  height: var(--s36);
  min-width: 0;
  min-height: 0;
  border-radius: 99px;
  padding: 0;
}
._type_dense__1fwmI._type_icon__2zHO7 {
  width: var(--s28);
  height: var(--s28);
}

/*FLAT STYLE*/
._view_flat__3cLSh,
._view_flat__3cLSh[class*="button"],
._view_flat__3cLSh:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_flat__3cLSh:hover,
._view_flat__3cLSh[class*="button"]:hover,
._view_flat__3cLSh:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__3cLSh:active,
._view_flat__3cLSh[class*="button"]:active,
._view_flat__3cLSh[class*="button"][class*="active"],
._view_flat__3cLSh[class*="button"]._view_focused__6xcuh {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__3cLSh[class*="disabled"]:disabled,
._view_flat__3cLSh[class*="disabled"]:disabled > span > svg,
._view_flat__3cLSh[class*="disabled"]:disabled:hover,
._view_flat__3cLSh[class*="disabled"]:disabled:active {
  color: var(--color30);
  opacity: 1;
  background-color: var(--color0);
  box-shadow: none;
}
/*END STYLE*/

/* FILLED STYLE*/
._view_filled__3cfIg[class*="button"],
._view_filled__3cfIg[class*="button"]:visited {
  box-shadow: var(--shadow4dp);
  background: var(--color60);
  color: var(--color0);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_filled__3cfIg[class*="button"]:hover {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__3cfIg[class*="button"]:active,
._view_filled__3cfIg[class*="button"][class*="active"]:active,
._view_filled__3cfIg[class*="button"]._view_focused__6xcuh {
  background: var(--color80);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__3cfIg[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
  opacity: 1;
}
/* END STYLE */

/*SMOOTH STYLE*/
._view_smooth__2-nzw[class*="button"],
._view_smooth__2-nzw[class*="button"]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_smooth__2-nzw[class*="button"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__2-nzw[class*="button"]:active,
._view_smooth__2-nzw[class*="button"]:active,
._view_smooth__2-nzw[class*="button"][class*="active"],
._view_smooth__2-nzw[class*="button"]._view_focused__6xcuh {
  background: var(--color20);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__2-nzw[class*="disabled"]:disabled,
._view_smooth__2-nzw[class*="disabled"]:disabled:hover {
  color: var(--color30);
  background-color: var(--color0);
  opacity: 1;
}
/* END STYLE */

/* OUTLINED STYLE*/
._view_outlined__3E1JE[class*="button"],
._view_outlined__3E1JE[class*="button"]:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30); /*border: 1px solid var(--color30);*/
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_outlined__3E1JE[class*="button"]:hover {
  box-shadow: 0 0 0 2px var(--color30);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__3E1JE[class*="button"]:active,
._view_outlined__3E1JE[class*="button"]._view_focused__6xcuh {
  color: var(--color100);
  background-color: var(--color5);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__3E1JE[class*="disabled"]:disabled {
  background-color: unset;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
/* END STYLE */

/* RAISED STYLE */
._view_raised__2c44m[class*="button"],
._view_raised__2c44m[class*="button"]:visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_raised__2c44m[class*="button"]:hover {
  background: var(--white);
  color: var(--color100);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__2c44m[class*="button"]:active,
._view_raised__2c44m[class*="button"]._view_focused__6xcuh {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__2c44m[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
}
/* END STYLE */

/* BUTTONS TYPES*/

/*--DEFAULT--*/
._type_default__1-tcq {
  text-transform: capitalize;
}

._type_default__1-tcq > li::after {
  margin: 0 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
}

._type_default__1-tcq span[class*="icon"] {
  margin-right: 0.5rem;
}

._type_default__1-tcq span[class*="breadcrumbs-collapsed"]::before {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
._type_default__1-tcq span[class*="breadcrumbs-collapsed"] {
  margin-right: 0;
  width: 2rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  padding: 0;
}
._type_default__1-tcq a[class*="breadcrumb"] {
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
}

/*MORE ICON COLOR*/
._view_primary__2P9Pb span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%232979ff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_success__2naue span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%2343a047'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_warning__2R-gY span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23d6981b'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_danger__2LjRc span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23F44336'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_default__3GHsQ span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235A5B6A'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_primary_alt__2LOAA span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235c6bc0'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_success_alt__3ov32 span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23069697'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_warning_alt__cdcid span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23fb8200'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_danger_alt__2E2aB span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ec407a'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
/*CHEVRON ICON COLOR*/
._view_primary__2P9Pb > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%232979FF'/></svg>");
}
._view_success__2naue > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%2343a047'/></svg>");
}
._view_warning__2R-gY > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23d6981b'/></svg>");
}
._view_danger__2LjRc > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23F44336'/></svg>");
}
._view_default__3GHsQ > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235a5b6a'/></svg>");
}
._view_primary_alt__2LOAA > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235c6bc0'/></svg>");
}
._view_success_alt__3ov32 > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23069697'/></svg>");
}
._view_warning_alt__cdcid > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23fb8200'/></svg>");
}
._view_danger_alt__2E2aB > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23ec407a'/></svg>");
}
/*--------*/

/*SMOOTH STYLE*/
._view_smooth__UPTaq {
  color: var(--color100);
}
._view_smooth__UPTaq span[class*="breadcrumbs-collapsed"] {
  background: var(--color30);
  color: var(--color100);
}
._view_smooth__UPTaq a[class*="breadcrumb"] {
  color: var(--color80);
}
._view_smooth__UPTaq a[class*="breadcrumb"] > span[class*="icon"] {
  color: var(--color30);
}
._view_smooth__UPTaq a[class*="breadcrumb"]:hover {
  background: var(--color5);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__UPTaq a[class*="breadcrumb"]:hover > span[class*="icon"] {
  color: var(--color50);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/* END STYLE */
._view_popover__1Byv9 div[class*="popover-content"] a[class*="menu-item"]:hover {
  background-color: var(--color5);
}
._view_popover__1Byv9 div[class*="popover-arrow"] {
  display: none;
}

.styles_container__1n-1s {
  display: grid;
  grid-template-areas:
    "header "
    "content";
  grid-template-columns: fit-content(100%);
  padding-top: 1em;
  padding-left: 20px;
  padding-right: 20px;

}

.styles_breadcrumbs__wOsw7 {
  grid-area: header;
  top: 4.5rem;

}

.styles_content__2lqGc {
  grid-area: content;
  padding-top: 2.5em;

}

.styles_form__2XNbZ {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: auto;
  text-align: center;
}

.styles_button__3M9wV {
  color: #61dafb;
}

.styles_icon__GG3V1 {
  justify-content: left;
  background: #00a6e9;
  text-align: left;
  max-width: 180px;
}

.styles_textField__1ytmy {
  position: relative;
  float: left;
  width: '100%';
  margin-right: 30px;
}
